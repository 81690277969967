import IEnquiry from "../IEnquiry";

export default class EnquiryService {

    static async SendMessage(enquiry: IEnquiry) : Promise<boolean> {
        return await fetch(`/enquiry`, {
            method: "post",
            body: JSON.stringify(enquiry),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.ok)
            .catch(_ => false);
    }
}