import {Alert, Button, Col, Form, FormLabel} from "react-bootstrap";
import React, {FormEvent} from "react";
import EnquiryService from "./services/EnquiryService";
import IEnquiry from "./IEnquiry";

export default class Enquiry extends React.Component<{}, {}> {

    public state: {
        validationErrors: string[],
        submissionStatus?: boolean
    }

    public constructor(props: any) {
        super(props);

        this.state = {
            validationErrors: [],
            submissionStatus: undefined
        };
    }

    public render() {
        return (<Col
            xs={{span: 12, order: 2}}
            sm={{span: 12, order: 2}}
            md={{span: 12, order: 2}}
            lg={{span: 3, order: 2}}
            xl={{span: 3, order: 2}}
            className="px-lg-3 px-xl-5 pt-lg-4 text-white">


            {
                this.state.submissionStatus !== true &&
                <Form id={"EnquiryForm"}
                        onSubmit={async (e: FormEvent<HTMLFormElement>) => await this.submitEnquiry(e)}>
                    <Form.Label for="name">Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder="Enter your name"/>
                    <Form.Label for="email">Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter a valid email address" />
                    <Form.Label for="body">Message</Form.Label>
                    <Form.Control as="textarea" name="body" rows={4} placeholder="Enter your message"/>

                    {
                        this.state.submissionStatus === false &&
                        <Alert id="alertFormError" className="mt-3" variant="danger">
                            Oh no!
                            <br/>
                            There was an issue submitting your form, please try again.
                        </Alert>
                    }

                        <Button variant="primary" className="mt-4 w-100 btn-submit" type="submit">
                            SUBMIT
                        </Button>

                    <ul id={"EnquiryFormErrors"}>
                        {
                            this.state.validationErrors.map((error: string, index: number) => <li key={index}>{error}</li>)
                        }
                    </ul>
                </Form>
            }

            {
                this.state.submissionStatus === true &&
                <Alert id="alertSuccess" variant="primary">
                    Thank you for your enquiry!
                    <br/>
                    Our team will reply to you shortly.
                </Alert>
            }
        </Col>);
    }

    private get enquiry(): IEnquiry {
        const form = document.getElementById("EnquiryForm") as HTMLFormElement;
        const data = new FormData(form);

        return {
            name: (data.get("name") as string).trim(),
            email: (data.get("email") as string).trim(),
            phone: (data.get("phone") as string).trim(),
            subject: (data.get("subject") as string).trim(),
            body: (data.get("body") as string).trim()
        };
    }

    private async submitEnquiry(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        const status: boolean = await EnquiryService.SendMessage(this.enquiry);
        this.setState({ submissionStatus: status });

        if (status) {
            const rowMain = document.getElementById("rowMain") as HTMLDivElement;
            rowMain.classList.add("pb-5");
        }
    }

    private validateForm(): boolean {
        const errors: string[] = new Array<string>();

        if (!this.enquiry.name || this.enquiry.name.trim() === "") {
            errors.push("Please enter your name.");
        }

        if (!this.enquiry.email || this.enquiry.email.trim() === "") {
            errors.push("Please enter your email address.");
        }

        if (!this.enquiry.phone || this.enquiry.phone.trim() === "") {
            errors.push("Please enter your phone number.");
        }

        if (!this.enquiry.subject || this.enquiry.subject.trim() === "") {
            errors.push("Please enter a brief title.");
        }

        if (!this.enquiry.body || this.enquiry.body.trim() === "") {
            errors.push("Please enter a brief message.");
        }

        this.setState({ validationErrors: errors });
        return errors.length === 0;
    }
}