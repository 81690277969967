import React, {Component} from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import Enquiry from "./Enquiry";

export default class App extends Component {
    static displayName = App.name;

    render() {
        const DolphinLogo = require('./images/Logo.png');
        const FooterImage = require('./images/footer-image.png')
        
        return (
            <Container fluid>
                <Row>
                    <Col xs="12" sm="12" md="12" lg={{ span: 3, offset: 4 }} xl={{ span: 3, offset: 4 }}>
                        <a href="https://www.consultdolphin.com" target="_blank">
                            <img src={DolphinLogo} className="w-100 pt-xl-5 mb-3" alt="Dolphin IT Solutions" />
                        </a>
                    </Col>
                </Row>
                <Row id="rowMain">
                    <Col
                        xs={{span: 12, order: 0}}
                        sm={{span: 12, order: 0}}
                        md={{span: 12, order: 0}}
                        lg={{span: 6, order: 1, offset: 1}}
                        xl={{ span: 6, order: 1, offset: 1 }} className="mb-3 mb-lg-0">
                        <div className="text">
                            <h1>
                                Realise Your Potential
                            </h1>
                            <h3>
                                Get the most out of your Microsoft apps
                            </h3>
                            <div className="Introduction mt-5 text-white">
                                <p className="lead">
                                    Dolphin IT Solutions is committed to helping you create a simplified and sustainable work environment through a variety of Microsoft services. 
                                </p>
                                <p className="lead mt-5">
                                    Modernise your business and never look back.
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Enquiry/>
                </Row>

                <div className="img-container">
                    <div className="img-wrapper">
                        <img src={FooterImage} className="img-footer" />
                    </div>
                </div>
            </Container>
        );
    }
}
